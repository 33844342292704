<template>
  <div class="dp-top-pro-recipe-carousel-card">
    <button
      type="button"
      @click="onClick"
      v-ripple="isPublishable ? $utilities.getRippleOptions('light') : false">
      <div class="dp-top-pro-recipe-carousel-card__thumbnail">
        <img
          :src="content.image_url || content.banner"
          :alt="content.name"
          @load="onImgLoaded" />
      </div>

      <div ref="cardContent" :class="cardContentClasses">
        <div class="dp-top-pro-recipe-carousel-card__content-head">
          <div
            v-if="
              content.barista &&
              isPublishPeriod(content.barista) &&
              content.barista.image_url
            "
            class="dp-top-pro-recipe-carousel-card__avatar">
            <ProAvatar
              :src="
                content.barista && isPublishPeriod(content.barista)
                  ? content.barista.image_url
                  : ''
              "
              alt=""
              :size="44" />
          </div>
          <h2 class="dp-top-pro-recipe-carousel-card__title">
            {{ content.name }}
          </h2>
          <div class="dp-top-pro-recipe-carousel-card__arrow">
            <Icon name="dpArrowRightR" :width="20" height="20" />
          </div>
        </div>

        <div
          v-if="content.appeal_word"
          class="dp-top-pro-recipe-carousel-card__content-body">
          <p class="dp-top-pro-recipe-carousel-card__subtitle">
            {{ content.appeal_word }}
          </p>
        </div>

        <div
          v-if="content.capsule && isPublishPeriod(content.capsule)"
          class="dp-top-pro-recipe-carousel-card__capsule">
          <Icon name="capsuleR" color="dpGrey66" :width="15" :height="15" />
          <span class="dp-top-pro-recipe-carousel-card__capsule-name">{{
            content.capsule.name
          }}</span>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useGtm } from '@gtm-support/vue2-gtm';
import { usePublishable } from '@/composables/usePublishable';

export default defineComponent({
  props: {
    content: {
      type: Object,
      default: undefined
    }
  },

  setup: (props, context) => {
    const gtm = useGtm();
    const { isPublishPeriod } = usePublishable();
    const cardContent = ref(undefined);
    const isPublishable = computed(() => isPublishPeriod(props.content));
    const getContentDetailPath = (id) =>
      isPublishable.value && id
        ? context.root.$customUrlScheme.dp(`/pro-recipe/detail/${id}`)
        : false;

    const onImgLoaded = () => {
      context.emit('on-img-loaded');
    };

    const sendToGa = () => {
      gtm.trackEvent({
        event: 'click_dp_top_fv_recipe',
        recipeId: props.content.id
      });
    };

    const onClick = async () => {
      await sendToGa();
      const url =
        (await getContentDetailPath(props.content.id)) || props.content.url;
      if (url) window.location.href = url;
    };

    const isBannerSlide = computed(() => props.content.banner);
    const cardContentClasses = computed(() => {
      return {
        'dp-top-pro-recipe-carousel-card__content': true,
        'dp-top-pro-recipe-carousel-card__content--banner': isBannerSlide.value
      };
    });

    return {
      cardContent,
      isPublishable,
      isPublishPeriod,
      isBannerSlide,
      cardContentClasses,
      getContentDetailPath,
      onImgLoaded,
      onClick
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-top-pro-recipe-carousel-card {
  button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    text-decoration: none;
    color: variables.$dpBlack01;
  }

  &__thumbnail {
    @include mixin.imageAspectRatio(343, 343);
  }

  &__content {
    position: absolute;
    right: 16px;
    bottom: -69px;
    left: 16px;
    margin: 0;
    padding: 16px;
    background-color: rgba($color: variables.$dpWhite01, $alpha: 90%);
    border-radius: 4px;
    box-shadow: 0 4px 16px rgba(#000, 0.09);
    overflow: hidden;

    > *:last-child {
      margin-bottom: 0;
    }

    &-head {
      display: flex;
      align-items: center;
      column-gap: 12px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-body {
      margin-bottom: 8px;
    }

    // FIXME: バナーのときにトンマナーを無視したい場合は以下のコメントアウトを外す
    // &--banner {
    //   margin-bottom: 2.75em;
    // }
  }

  &__avatar {
    width: 44px;
  }

  &__title {
    @include mixin.clamp(2);

    flex: 1;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.45;
    font-weight: bold;
  }

  &__arrow {
    display: grid;
    place-items: center;
    width: 20px;
  }

  &__subtitle {
    @include mixin.clamp(2);

    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
  }

  &__capsule {
    display: grid;
    place-items: center start;
    grid-template-columns: 16px 1fr;
    gap: 3px;
    color: variables.$dpGrey66;
    font-size: 12px;

    &-name {
      @include mixin.clamp(1);
    }
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dp-top-pro-recipe-carousel-card" }, [
    _c(
      "button",
      {
        directives: [
          {
            name: "ripple",
            rawName: "v-ripple",
            value: _vm.isPublishable
              ? _vm.$utilities.getRippleOptions("light")
              : false,
            expression:
              "isPublishable ? $utilities.getRippleOptions('light') : false",
          },
        ],
        attrs: { type: "button" },
        on: { click: _vm.onClick },
      },
      [
        _c(
          "div",
          { staticClass: "dp-top-pro-recipe-carousel-card__thumbnail" },
          [
            _c("img", {
              attrs: {
                src: _vm.content.image_url || _vm.content.banner,
                alt: _vm.content.name,
              },
              on: { load: _vm.onImgLoaded },
            }),
          ]
        ),
        _c("div", { ref: "cardContent", class: _vm.cardContentClasses }, [
          _c(
            "div",
            { staticClass: "dp-top-pro-recipe-carousel-card__content-head" },
            [
              _vm.content.barista &&
              _vm.isPublishPeriod(_vm.content.barista) &&
              _vm.content.barista.image_url
                ? _c(
                    "div",
                    { staticClass: "dp-top-pro-recipe-carousel-card__avatar" },
                    [
                      _c("ProAvatar", {
                        attrs: {
                          src:
                            _vm.content.barista &&
                            _vm.isPublishPeriod(_vm.content.barista)
                              ? _vm.content.barista.image_url
                              : "",
                          alt: "",
                          size: 44,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "h2",
                { staticClass: "dp-top-pro-recipe-carousel-card__title" },
                [_vm._v(" " + _vm._s(_vm.content.name) + " ")]
              ),
              _c(
                "div",
                { staticClass: "dp-top-pro-recipe-carousel-card__arrow" },
                [
                  _c("Icon", {
                    attrs: { name: "dpArrowRightR", width: 20, height: "20" },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.content.appeal_word
            ? _c(
                "div",
                {
                  staticClass: "dp-top-pro-recipe-carousel-card__content-body",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "dp-top-pro-recipe-carousel-card__subtitle",
                    },
                    [_vm._v(" " + _vm._s(_vm.content.appeal_word) + " ")]
                  ),
                ]
              )
            : _vm._e(),
          _vm.content.capsule && _vm.isPublishPeriod(_vm.content.capsule)
            ? _c(
                "div",
                { staticClass: "dp-top-pro-recipe-carousel-card__capsule" },
                [
                  _c("Icon", {
                    attrs: {
                      name: "capsuleR",
                      color: "dpGrey66",
                      width: 15,
                      height: 15,
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass:
                        "dp-top-pro-recipe-carousel-card__capsule-name",
                    },
                    [_vm._v(_vm._s(_vm.content.capsule.name))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }